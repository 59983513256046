import { Injectable } from '@angular/core';
import { Download, ReportService } from './report.service';
import { RestApiService } from 'src/app/API/restapi';
import { map } from 'rxjs/operators';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { CheckIn } from 'src/model/checkIn.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CheckInAdapter {
  adapt(item: any): CheckIn {
    return new CheckIn(
      item.id,
      new Date(item.date),
      new Date(item.dateCreated),
      item.companyId,
      item.remark,
      item.username,
      item.role,
      item.roleId
    )
  }
}

@Injectable({
  providedIn: 'root'
})
export class MidMonthService extends ReportService {

  readonly defaultStartDate = 16;
  readonly defaultEndDate = 15;
  readonly noOfPreviousMonth = 6;

  constructor(private api: RestApiService, private constructApi: ConstructAPI, private adapter: CheckInAdapter, private datePipe: DatePipe) { super() }

  // #region 'CheckIns'
  getCompaniesLatestCheckIns(compId?: number, aDate?: string, roleId?: number) {
    return new Promise<CheckIn[]>((resolve, reject) => {
      this.api.get<CheckIn[]>(this.constructApi.getCompaniesLatestCheckIns(compId, aDate, roleId)).pipe(
        map(data => data.map(d => this.adapter.adapt(d)))
      ).subscribe({
        next: response => resolve(response),
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    })
  }
  // #endregion

  //#region 'MidMonth Report'
  getMidMonth(compID: number, startDate: string, endDate: string, omitSchemeA: boolean, useVersion1: boolean = false) {
    let url = useVersion1 ? this.constructApi.getMidMonthV1(compID, startDate, endDate, omitSchemeA) : this.constructApi.getMidMonth(compID, startDate, endDate, omitSchemeA);
    return new Promise<Download>((resolve, reject) => {
      this.api.getFile(url).subscribe({
        next: response => {
          let filename = this.getFilenameFromContentDisposition(response.headers.get('content-disposition'));
          const blob = new Blob([response.body as BlobPart], { type: response.headers.get('Content-Type') });
          const url = window.URL.createObjectURL(blob);
          let result: Download = {url: url, filename: filename}
          resolve(result)
        },
        error: err => {
          this.api.handleError(err);
          console.error(err);
          reject(err);
        }
      })
    })
  }
  //#endregion

  getmidMonthEndDateLocalStorageKey(): string {
    const compID = Number(sessionStorage.getItem("companyId"));
    return `${compID}-midMonthEndDate`;
  }

  getLastEndDateFromStorage(): Date {
    const s = localStorage.getItem(this.getmidMonthEndDateLocalStorageKey());
    return s ? new Date(s) : null;
  }

  isMidMonth(): boolean {
    return new Date().getDate() >= this.defaultEndDate;
  }

  getNextEndDate(isMidMonth: boolean, lastEndDate: Date): Date {
    let nextEndDate = new Date();
    if (lastEndDate) { // 2 Sep
      nextEndDate = new Date(lastEndDate);
      if (nextEndDate.getDate() < this.defaultEndDate) nextEndDate.setDate(this.defaultEndDate);
      else nextEndDate.setMonth(nextEndDate.getMonth() + 1, this.defaultEndDate);
    }
    else
      nextEndDate.setMonth(new Date().getMonth() - (isMidMonth ? 0 : 1), this.defaultEndDate);
    return nextEndDate;
  }

  saveMidMonthRange(startDate: Date, endDate: Date) {
    const isDefaultStart = (startDate.getDate() === this.defaultStartDate);
    const isDefaultEnd = (endDate.getDate() === this.defaultEndDate);
    const isDefaultRange = ((endDate.getMonth() - startDate.getMonth() === 1 && endDate.getFullYear() === startDate.getFullYear()) || (endDate.getMonth() - startDate.getMonth() === -11 && endDate.getFullYear() === startDate.getFullYear() + 1 ));
    const lastEndDate = this.getLastEndDateFromStorage();
    const isLatest = lastEndDate ? endDate > new Date(lastEndDate) : true;
    if (isDefaultStart && isDefaultEnd && isDefaultRange && isLatest)
      localStorage.setItem(this.getmidMonthEndDateLocalStorageKey(), endDate.toDateString());
  }

  getMidMonthLink(compID: string) {
    const today = new Date();
    const isMidMonth = this.isMidMonth();
    const lastEndDate = this.getLastEndDateFromStorage();
    const nextEndDate = this.getNextEndDate(isMidMonth, lastEndDate);
    const startDate = new Date(nextEndDate);
    startDate.setMonth(nextEndDate.getMonth() - 1, this.defaultStartDate);

    if ((isMidMonth && (!lastEndDate || today >= nextEndDate )) || (!isMidMonth && lastEndDate && today >= nextEndDate))
      return `midMonth/${compID}/${this.datePipe.transform(nextEndDate, 'yyyy-MM-dd')}`;
  }
}
