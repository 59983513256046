import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Customer } from 'src/model/customer.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RestApiService } from 'src/app/API/restapi';
import { DatePipe } from '@angular/common'
import { SharedService } from 'src/app/Shared/shared-service.service';
import { ConstructAPI } from 'src/app/API/constructAPI';
import { Enums } from 'src/app/Shared/enums';
import { EPF } from 'src/model/epf.model';

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.component.html',
  styleUrls: ['./update-customer.component.css'],
  providers: [DatePipe]
})
export class UpdateCustomerComponent implements OnInit {


  customerToUpdate: Customer;

  customerName: string;
  icNumber: string;
  contactNo: string;
  address: string;
  address2: string;
  dob: Date;
  otherId: string;
  race: string;
  gender: string;

  @Input() epfDetails: EPF;

  oldRace: string;
  oldEPFDate: Date;
  oldEPFAmount: number;
  oldDob: Date;

  disableSubmit = false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();


  customerNameNull = false;
  icNumberNull = false;
  contactNoNull = false;
  addressNull = false;
  address2Null = false;


  constructor(private activeModal: NgbActiveModal, private modalService: NgbModal, private api: RestApiService,
    private constructApi: ConstructAPI, public sharedService: SharedService, public enums: Enums, public datepipe: DatePipe) { }

  ngOnInit(): void {

    this.customerName = this.customerToUpdate.Name;
    this.icNumber = this.customerToUpdate.ICNumber;
    this.contactNo = this.customerToUpdate.ContactNo;
    this.address = this.customerToUpdate.Address;
    this.address2 = this.customerToUpdate.Address2;
    this.oldEPFDate = (this.epfDetails != undefined) ? this.epfDetails.Date : undefined;
    this.oldEPFAmount = (this.epfDetails != undefined) ? this.epfDetails.Amount : undefined;
    this.oldDob = this.customerToUpdate.DOB;
    this.dob = this.customerToUpdate.DOB;
    this.otherId = this.customerToUpdate.OtherID;
    this.oldRace = this.customerToUpdate.Race;
    this.race = this.customerToUpdate.Race;
    this.gender = this.customerToUpdate.Gender;


    if (this.epfDetails == undefined)
      this.epfDetails = new EPF(undefined, undefined, undefined, undefined, undefined, undefined, undefined)

  }


  submit() {
    this.validFields() ? this.sharedService.openAlert('Please fill in all the fields correctly!', this.enums.DANGER_ALERT) :
      this.updateDetails();

  }


  validField(field: string) {
    switch (field) {
      case 'customerName': this.customerNameNull = (this.customerName === ''); break;
      case 'icNumber': this.icNumberNull = (this.icNumber === ''); break;
      case 'contactNo': this.contactNoNull = (this.contactNo === ''); break;
      case 'address': this.addressNull = (this.address === ''); break;
      case 'address2': this.address2Null = (this.address2 === ''); break;
    }
  }

  validFields() {

    this.customerNameNull = (this.customerName === '');
    this.icNumberNull = (this.icNumber === '');
    this.contactNoNull = (this.contactNo === '');
    var addressNull = (this.address === '');

    if (this.icNumberNull == false) {
      var icNumberInvalid = !this.sharedService.checkIfICValid(this.icNumber);
    }

    // var epfDateNull = (this.epfDetails == undefined || this.epfDetails.Date == undefined);
    // var epfAmountNull = (this.epfDetails == undefined || this.epfDetails.Amount == undefined || this.epfDetails.Amount == 0);

    // var dobNull = this.dob == undefined;

    var raceNull = this.race == undefined || this.race == "";
    var genderNull = this.gender == undefined || this.gender == "";

    if (this.sharedService.isBranchAccountHandler())
      return (this.customerNameNull || this.icNumberNull || this.contactNoNull || icNumberInvalid || addressNull || raceNull || genderNull)
    else if (this.sharedService.isStaff())
      return addressNull;
    // else {
    //   return epfAmountNull || epfDateNull
    // }

  }


  updateDetails() {

    if (this.sharedService.isOperatingUser() || this.sharedService.isBossAccount())
      this.updateCustomer();
  }

  updateCustomer() {
    this.disableSubmit = true;



    const icNumberFormat = this.icNumber.replace(/-/g, "",);
    var data

    if (this.sharedService.isBranchAccountHandler() || this.sharedService.isBossAccount()) {
      data = {
        "name": this.customerName,
        "icNumber": icNumberFormat,
        "contactNo": this.contactNo,
        "ctosNumber": this.customerToUpdate.CtosNumber,
        "ctosStatus": this.customerToUpdate.CtosStatus,
        "status": this.customerToUpdate.Status,
        "dateCreated": this.customerToUpdate.DateCreated,
        "handler": this.customerToUpdate.HandlerId,
        "companyId": this.customerToUpdate.CompanyId,
        "address": this.address,
        "address2": this.address2,
        "otherId": this.otherId,
        "race": this.race,
        "gender": this.gender

      }
    } else if (this.sharedService.isStaff()) {
      data = {
        "ctosNumber": this.customerToUpdate.CtosNumber,
        "ctosStatus": this.customerToUpdate.CtosStatus,
        "address": this.address,
        "companyId": this.customerToUpdate.CompanyId,
        "address2": this.address2,
        "otherId": this.otherId,
        "race": this.race,
        "gender": this.gender

      }

    }


    var xhr = this.api.putRequest(this.constructApi.getUpdateCustomer(this.customerToUpdate.ID), data);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        this.disableSubmit = false;

        if (xhr.status == 200) {

          this.sharedService.openAlert("Successfully updated customer", this.enums.SUCCESS_ALERT)

          this.passEntry.emit(true);
          this.reset();
        }
        else
          this.sharedService.openErrorMessage(xhr);


      }

    }
  }

  updateDOB() {
    if (this.oldDob != this.dob || this.oldDob == undefined) {
      var dobStr = this.datepipe.transform(this.dob, 'yyyy-MM-dd');
      var data = {
        "dob": dobStr
      }

      var xhr = this.api.putRequest(this.constructApi.getUpdateDOB(this.customerToUpdate.ID), data);

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.disableSubmit = false;

          if (xhr.status == 200) {
            this.sharedService.openAlert("Successfully updated customer details", this.enums.SUCCESS_ALERT)
            this.passEntry.emit(true);
            this.reset();
          }
          else {
            this.sharedService.openErrorMessage(xhr);
          }

        }
      }
    }
    else {
      this.sharedService.openAlert("DOB is up to date!", this.enums.DANGER_ALERT)
    }


  }

  updateEPFDetails() {
    if ((this.oldEPFDate == undefined || this.oldEPFDate != this.epfDetails.Date || this.oldEPFAmount == undefined || this.oldEPFAmount != this.epfDetails.Amount)) {
      var epfStr = this.datepipe.transform(this.epfDetails.Date, 'yyyy-MM-dd');

      var data = {
        "date": epfStr,
        "amount": this.epfDetails.Amount,
        "hasIAccount": this.epfDetails.HasIAccount,
        "iAccountUsername": this.epfDetails.IAccountUsername,
        "iAccountPassword": this.epfDetails.IAccountPassword,
        "remark": this.epfDetails.Remark
      }

      if (this.oldEPFDate == undefined && this.oldEPFAmount == undefined)
        var xhr = this.api.postRequest(this.constructApi.getUpdateEPFDetails(this.customerToUpdate.ID), data);
      else
        var xhr = this.api.putRequest(this.constructApi.getUpdateEPFDetails(this.customerToUpdate.ID), data);

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          this.disableSubmit = false;

          if (xhr.status == 200) {

            if (this.oldDob != this.dob || this.oldDob == undefined)
              this.updateDOB();
            else {
              this.sharedService.openAlert("Successfully updated customer details", this.enums.SUCCESS_ALERT)
              this.passEntry.emit(true);
              this.reset();
            }

          }
          else {
            this.sharedService.openErrorMessage(xhr);
          }

        }
      }
    }
    else
      this.sharedService.openAlert("EPF DETAILS are up to date!", this.enums.DANGER_ALERT);

  }

  reset() {
    this.activeModal.close();
    this.updateCustomer = null;

  }

}
