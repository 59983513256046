import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConstructAPI } from 'src/app/API/constructAPI';
import { RestApiService } from 'src/app/API/restapi';
import { EnlargeImageComponent } from 'src/app/Images/enlarge-image/enlarge-image.component';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { Enums } from 'src/app/Shared/enums';
import { SharedService } from 'src/app/Shared/shared-service.service';

import { Customer } from 'src/model/customer.model';
import { UploadImageComponent } from '../upload-image/upload-image.component';
import { FileInterface } from 'src/model/file.interface';





@Component({
  selector: 'app-view-thumbnails',
  templateUrl: './view-thumbnails.component.html',
  styleUrls: ['./view-thumbnails.component.css']
})
export class ViewThumbnailsComponent implements OnInit {
  pdfContent: any;
  @ViewChild('pdfview') pdfview: ElementRef;


  constructor(private activeModal: NgbActiveModal, public sharedService: SharedService, public enums: Enums,
    private restApi: RestApiService, private constructApi: ConstructAPI, private cdr: ChangeDetectorRef) { }

  @Input() setID: number;
  @Input() customerID: number;
  @Input() customer: Customer;
  @Input() isValidFile: boolean;
  @Input() loanId: number;
  @Input() contactID: number;
  @Input() reserveGroupID: number;


  @Input() imageArr: FileInterface[] = new Array;
  @Input() fileArr: FileInterface[] = new Array;
  ngOnInit(): void {

    if (this.imageArr.length == 0 && this.fileArr.length == 0) {
      if (this.setID != undefined || this.loanId != undefined || this.contactID != undefined || this.reserveGroupID != undefined)
        this.getFiles();
      else if (this.customerID != undefined) {
        if (this.customer == undefined && (this.sharedService.isAdmin() || this.sharedService.isBossAccount() || this.sharedService.isFinance()))
          this.getCustomerInfos();
        else
          this.getCustomerFiles();
      }
    }
  }

  reset() {
    this.activeModal.close();
  }

  private getFiles() {
    this.imageArr = [];
    this.fileArr = [];

    var xhttp;
    if (this.setID != undefined)
      xhttp = this.restApi.getRequest(this.constructApi.getAllSetFiles(this.setID));
    else if (this.loanId != undefined)
      xhttp = this.restApi.getRequest(this.constructApi.getCustomerLoanAllFiles(this.customerID, this.loanId));
    else if (this.contactID != undefined)
      xhttp = this.restApi.getRequest(this.constructApi.getCustomerContactAllFiles(this.customerID, this.contactID));
    else if (this.reserveGroupID != undefined)
      xhttp = this.restApi.getRequest(this.constructApi.getReserveGroupAllFiles(this.reserveGroupID));


    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var lastModifiedDate = (json["Last-Modified"] == undefined) ? json["Last-Modified"] : new Date(json["Last-Modified"]);
          let file = { label: json.Name, url: json.Data, contentType: json["Content-Type"], lastModified: lastModifiedDate, uploadedBy: json.uploadedBy }

          if (file.contentType.includes("image/") == false) {
            this.fileArr.push(file)
          }
          else
            this.imageArr.push(file)

        }
        );
        this.fileArr.sort((a, b) => (a.lastModified > b.lastModified) ? -1 : 1);
        this.imageArr.sort((a, b) => (a.lastModified > b.lastModified) ? -1 : 1);
      }
    };

  }

  checkCanDelete() {
    if (this.sharedService.isBranchManager() == true) {

      if (this.setID != undefined) {
        if (this.isValidFile == false)
          return true;
        else
          return false;
      }
      else
        return false;
    }

    else if (this.sharedService.isAdmin()) {
      if (this.setID != undefined)
        return false;
      else
        return true;
    }
    else if (this.sharedService.isBossAccount())
      return true;
    else
      return false;

  }

  private getCustomerFiles() {
    this.fileArr = [];
    this.imageArr = [];

    var xhttp = this.restApi.getRequest(this.constructApi.getAllFiles(this.customerID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var jsonArray = JSON.parse((xhttp.responseText));

        jsonArray.forEach(json => {
          var lastModifiedDate = (json["Last-Modified"] == undefined) ? json["Last-Modified"] : new Date(json["Last-Modified"]);
          let file = { label: json.Name, url: json.Data, contentType: json["Content-Type"], lastModified: lastModifiedDate, uploadedBy: json.uploadedBy }

          if (file.contentType.includes("image/") == false) {
            this.fileArr.push(file)
          }
          else
            this.imageArr.push(file)

        }
        );

        this.fileArr.sort((a, b) => (a.lastModified > b.lastModified) ? -1 : 1);
        this.imageArr.sort((a, b) => (a.lastModified > b.lastModified) ? -1 : 1);
      }
    };

  }



  openEnlargeImageModal(fileName: string, index: number) {
    const modalRef = this.sharedService.openModal(EnlargeImageComponent, 'largeModal');
    modalRef.componentInstance.fileName = fileName;
    modalRef.componentInstance.fileList = this.imageArr;
    modalRef.componentInstance.currentFileIndex = index;

    if (this.setID != undefined) {
      modalRef.componentInstance.isSetFile = true;
      modalRef.componentInstance.setId = this.setID;
      modalRef.componentInstance.isValidFile = this.isValidFile;
    }
    else if (this.reserveGroupID != undefined) {
      modalRef.componentInstance.reserveGroupID = this.reserveGroupID;
    }
    else if (this.customerID != undefined) {
      modalRef.componentInstance.customerId = this.customerID;
      modalRef.componentInstance.customer = this.customer
    }




    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var isDirty = entry;

      // use the confirmation to do something

      if (isDirty == true) {
        this.imageArr.splice(index, 1);
        this.cdr.detectChanges();
      }


    });

  }

  downloadFile(filename: string) {
    var encoded = encodeURIComponent(filename);
    if (this.setID != undefined)
      var xhr = this.restApi.getRequest(this.constructApi.getSetFileFull(this.setID, encoded));
    else if (this.reserveGroupID != undefined)
      var xhr = this.restApi.getRequest(this.constructApi.getReserveGroupFileFull(this.reserveGroupID, encoded));
    else if (this.customerID != undefined)
      var xhr = this.restApi.getRequest(this.constructApi.getFullFile(this.customerID, encoded));
    xhr.responseType = "blob";
    xhr.onreadystatechange = () => {

      if (xhr.readyState === 4) {
        if (xhr.status == 200) {
          this.sharedService.generateDownloadLink(xhr, filename);
        }
        else {
          this.sharedService.openAlert("Some error occurred. Please try again later", this.enums.DANGER_ALERT);

        }
      }

    };
  }


  confirmDeleteFile(filename: string, index: number) {

    const modalRef = this.sharedService.openModal(ConfirmationDialogComponent);
    modalRef.componentInstance.message = "Are you sure you want to delete this file (" + filename + ") ?";

    //get confirmation
    modalRef.componentInstance.passEntry.subscribe((entry) => {
      var confirmation = entry;

      // use the confirmation to do something

      if (confirmation == true) {
        if (this.setID != undefined)
          this.deleteFile(this.setID, undefined, undefined, filename, index);
        else if (this.customerID != undefined)
          this.deleteFile(undefined, this.customerID, undefined, filename, index);
        else if (this.reserveGroupID != undefined)
          this.deleteFile(undefined, undefined, this.reserveGroupID, filename, index);
      }


    });

  }

  deleteFile(setID: number, customerID: number, reserveGroupID: number, filename: string, index: number) {
    var xhttp;

    var encoded = encodeURIComponent(filename);
    if (this.setID != undefined)
      xhttp = this.restApi.deleteRequest(this.constructApi.getSetFileFull(setID, encoded))
    else if (this.customerID != undefined)
      xhttp = this.restApi.deleteRequest(this.constructApi.getFullFile(customerID, encoded))
    else if (this.reserveGroupID != undefined)
      xhttp = this.restApi.deleteRequest(this.constructApi.getReserveGroupFileFull(reserveGroupID, encoded))

    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        // Typical action to be performed when the document is ready:
        this.sharedService.openAlert("Successfully deleted file!", this.enums.SUCCESS_ALERT);
        // window.location.reload();
        this.fileArr.splice(index, 1);
        this.cdr.detectChanges();

      }
    };
  }

  previewFile(filename: string) {

    var encoded = encodeURIComponent(filename);
    if (this.setID != undefined)
      window.open(this.restApi.apiUrl + this.constructApi.getSetFileFull(this.setID, encoded));
    else if (this.reserveGroupID != undefined)
      window.open(this.restApi.apiUrl + this.constructApi.getReserveGroupFileFull(this.reserveGroupID, encoded));
    else if (this.customerID != undefined)
      window.open(this.restApi.apiUrl + this.constructApi.getFullFile(this.customerID, encoded));
  }


  getCustomerInfos() {
    // this.viewCustomer == undefined;
    var xhttp = this.restApi.getRequest(this.constructApi.getCustomerDetails(this.customerID));
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState == 4 && xhttp.status == 200) {
        var json = JSON.parse((xhttp.responseText));
        var reserveAmount = (json.reserve == undefined) ? 0 : json.reserve;
        this.customer = new Customer(json.id, json.name, json.occupation,
          json.salary, json.salaryDay, json.icNumber, json.contactNo, json.remark, json.ctosNumber, json.ctosStatus,
          json.status, json.dateCreated, json.handler, json.companyId, json.customerCode, json.companyCode, reserveAmount, json.badDebt, json.address, undefined, json.lastFormJDate, undefined, json.epfDate, json.address2, undefined, json.dob);

        this.getCustomerFiles();
      }
    };

  }


  addLoanDocModal() {
    const modalRef = this.sharedService.openModal(UploadImageComponent);

    modalRef.componentInstance.selectedCustomerId = this.customerID;
    modalRef.componentInstance.isLoanDocument = true;
    modalRef.componentInstance.loanId = this.loanId;

    modalRef.componentInstance.passEntry.subscribe((entry) => {

      var loanId = Number(entry);

      if (loanId != undefined) {
        this.getFiles();
      }


    });
  }



}
